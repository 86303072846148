import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

//  For User
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

//  For admin
import NotAllow from 'src/content/pages/NotAllow';
import NotVerified from 'src/content/pages/NotVerified';

import AdminSidebarLayout from './layouts/AdminSidebarLayout';
import ManagerSidebarLayout from './layouts/ManagerSidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { useAuth } from './Authentication/AuthContext';
import { getDecryptedData } from './utility/LocalStorageManager';
import { useSelector } from 'react-redux';
import EditTemplate from './content/applications/NewTemplatesCarosel/EditTemplate';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Login = Loader(lazy(() => import('src/content/Login/index')));
const Register = Loader(lazy(() => import('src/content/Register')));
const ManagerRegister = Loader(lazy(() => import('src/managerContents/Register')));
const Facebook = Loader(
  lazy(() => import('src/content/applications/EmbededSignup'))
);

// Applications
const Profile = Loader(lazy(() => import('src/content/applications/profile')));

const BusinessProfile = Loader(
  lazy(() => import('src/content/applications/BusinessProfile'))
);

const ResetPassword = Loader(
  lazy(() => import('src/content/applications/ResetPassword'))
);

const Customer = Loader(
  lazy(() => import('src/content/applications/Customer'))
);
const Analytics = Loader(
  lazy(() => import('src/content/applications/Analytics'))
);
const AnalyticsData = Loader(
  lazy(() => import('src/content/applications/AnalyticsData'))
);

const AgentActivity = Loader(
  lazy(() => import('src/content/applications/AgentActivity'))
);
const Orders = Loader(
  lazy(() => import('src/content/applications/Orders'))
);

const Dashboard = Loader(
  lazy(() => import('src/content/applications/Dashboard'))
);
const Teams = Loader(lazy(() => import('src/content/applications/Teams')));

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);

const Template = Loader(
  lazy(() => import('src/content/applications/Templates'))
);

const NewTemplate = Loader(
  lazy(() => import('src/content/applications/NewTemplates'))
);

const NewTemplateCarosel = Loader(
  lazy(() => import('src/content/applications/NewTemplatesCarosel'))
);

const NewTemplateCatalog = Loader(
  lazy(() => import('src/content/applications/NewTemplates All WIth Catalog'))
);

const Campaigns = Loader(
  lazy(() => import('src/content/applications/Campaigns'))
);
const CampaignDetails = Loader(
  lazy(() => import('src/content/applications/CampaignDetails'))
);
const UserLogs = Loader(
  lazy(() => import('src/content/applications/UserLogs'))
);
const SingleUserLogs = Loader(
  lazy(() => import('src/content/applications/UserLogs/SingleUserLogs'))
);
const Media = Loader(lazy(() => import('src/content/applications/Media')));
const Manage = Loader(lazy(() => import('src/content/applications/Manage')));

const PlansPage = Loader(
  lazy(() => import('src/content/applications/Manage/PlansPage'))
);

const Notifications = Loader(
  lazy(() => import('src/content/applications/Notifications'))
);

const Chatbot = Loader(lazy(() => import('src/content/applications/Chatbot')));

const Tags = Loader(lazy(() => import('src/content/applications/Tags')));

const FlowForms = Loader(
  lazy(() => import('src/content/applications/FlowForms'))
);

const FlowFormResponses = Loader(
  lazy(() => import('src/content/applications/FlowFormResponses'))
);

const BuildChatbot = Loader(
  lazy(() => import('src/content/applications/BuildChatbot'))
);

const Catalog = Loader(
  lazy(() => import('src/content/applications/Catalogue'))
);

const Products = Loader(
  lazy(() =>
    import('src/content/applications/Catalogue/products/productTable.js')
  )
);

const Calendar = Loader(
  lazy(() => import('src/content/applications/Calendar'))
);

const FacebookLogin = Loader(
  lazy(() => import('src/content/applications/FacebookLogin'))
);

const AdAnalytics = Loader(
  lazy(()=> import('src/content/applications/AdAnalytics'))
)

const GoogleContacts = Loader(
  lazy(() => import('src/content/applications/GoogleContacts'))
);

const AdsCreate = Loader(
  lazy(() => import('src/content/applications/CreateAd'))
);

const QRPage = Loader(lazy(() => import('src/content/applications/QRPage')));
const Support = Loader(lazy(() => import('src/content/applications/Support')));

//  Admin Pages

const AdminLogin = Loader(lazy(() => import('src/adminContents/Login')));
const ManagerLogin= Loader(lazy(() => import('src/managerContents/Login')));

const AdminDashboard = Loader(
  lazy(() => import('src/adminContents/applications/Dashboard'))
);
const ManagerDashboard = Loader(
  lazy(() => import('src/managerContents/applications/Dashboard'))
);
const AdminCustomers = Loader(
  lazy(() => import('src/adminContents/applications/Customers'))
);
const ManagerCustomers = Loader(
  lazy(() => import('src/managerContents/applications/Customers'))
);
const AdminBusinesses = Loader(
  lazy(() => import('src/adminContents/applications/Businesses'))
);
const ManagerBussinesses = Loader(
  lazy(() => import('src/managerContents/applications/BussinessAdmins'))
);
const AdminSingleCustomer = Loader(
  lazy(() => import('src/adminContents/applications/Customers/singleCustomer'))
);
const AdminPlans = Loader(
  lazy(() => import('src/adminContents/applications/Plans'))
);
const AdminSupport = Loader(
  lazy(() => import('src/adminContents/applications/Support'))
);
const Manager = Loader(
  lazy(() => import('src/adminContents/applications/Manager'))
);
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const AdminCurrency = Loader(
  lazy(() => import('src/adminContents/applications/Currency'))
);
const AdminChatbot = Loader(
  lazy(() => import('src/adminContents/applications/Chatbot'))
);
const AdminCampaigns = Loader(
  lazy(() => import('src/adminContents/applications/Campaign'))
);
const UserLogin = Loader(
  lazy(() => import('src/content/applications/UserLogin'))
);

//  Implementing plans redirection
// const AdminLogin = Loader(lazy(() => import('src/adminContents/Login')));

const LoginRoute = ({ element, ...rest }) => {
  const userInfo = getDecryptedData('userInfo');

  if (userInfo) {
    return <Navigate to="/" replace {...rest} />;
  } else {
    return element;
  }
};
const LoginManagerRoute = ({ element, ...rest }) => {
  const managerInfo = getDecryptedData('managerInfo');

  if (managerInfo) {
    return <Navigate to="/manager/dashboard" replace {...rest} />;
  } else {
    return element;
  }
};

const PrivateRoute = ({ element,premium, ...rest }) => {
  const { isAuthenticated } = useAuth();
  const { businessProfileData } = useSelector((state) => state.wapp);
  const userInfo = getDecryptedData('userInfo');

  if (!isAuthenticated && !userInfo) {
    return <Navigate to="/login" replace {...rest} />;
  }

  const isDashboardOrPlans =
    location.pathname === '/plans' || location.pathname === '/login';

  if (
    !isDashboardOrPlans &&
    (userInfo?.planName === null || userInfo?.planName === 'null')
  ) {
    return <Navigate to="/plans" replace {...rest} />;
  }
if(premium){
    const userInfo = getDecryptedData('userInfo');
    if (userInfo.planName !== 'Premium') {
      // return <NotAllow/>
    }
}
  return element;
};

const PrivateAdminRoute = ({ element, ...rest }) => {
  const { isAdminAuthenticated } = useAuth();

  if (!isAdminAuthenticated) {
    return <Navigate to="/admin/login" replace {...rest} />;
  }

  return element;
};
const PrivateManagerRoute = ({ element, ...rest }) => {
  const { isManagerAuthenticated } = useAuth();
  if (!isManagerAuthenticated) {
    return <Navigate to="/manager/login" replace {...rest} />;
  }

  return element;
};

const routes = [
  //  User Routes
  {
    path: '/login',
    element: <LoginRoute element={<BaseLayout />} />,
    children: [
      {
        path: '',
        element: <Login />
      }
    ]
  },
  {
    path: '/register',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Register />
      }
    ]
  },
  {
    path: '/wappbizz-user-login',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <UserLogin />
      }
    ]
  },

  {
    path: '/admin',
    element: <BaseLayout />,
    children: [{ path: '', element: <AdminLogin /> }]
  },

  {
    path: '/manager',
    element: <LoginManagerRoute element={<BaseLayout />} />,
    children: [
      {
        path: '',
        element: <ManagerLogin />
      }
    ]
  },
  {
    path: '/manager/register',
    element: <BaseLayout />,
    children: [{ path: '', element: <ManagerRegister /> }]
  },

  {
    path: '/admin',
    element: <PrivateAdminRoute element={<AdminSidebarLayout />} />,
    children: [
      { path: '/admin/dashboard', element: <AdminDashboard /> },
      { path: '/admin/customers', element: <AdminCustomers /> },
      { path: '/admin/businesses', element: <AdminBusinesses /> },
      {
        path: '/admin/businesses/singleBusiness/:customerId',
        element: <AdminSingleCustomer />
      },
      { path: '/admin/plans', element: <AdminPlans /> },
      { path: '/admin/currency', element: <AdminCurrency /> },
      {
        path: '/admin/customers/singleCustomer/:customerId',
        element: <AdminSingleCustomer />
      },
      { path: '/admin/campaign', element: <AdminCampaigns /> },
      { path: '/admin/chatbot', element: <AdminChatbot /> },
      { path: '/admin/support', element: <AdminSupport /> },
      { path: '/admin/manager', element: <Manager /> }
    ]
  },
  {
    path: '/manager',
    element: <PrivateManagerRoute element={<ManagerSidebarLayout />} />,
    children: [
      { path: '/manager/dashboard', element: <ManagerDashboard /> },
      { path: '/manager/customers', element: <ManagerCustomers /> },
      // { path: '/manager/bussinesses', element: <ManagerBussinesses /> },
      {
        path: '*',
        element: <Status404 />
      }
     
    ]
  },
  //  Client Sub Routes  //
  {
    path: '',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      // Main sidebar routes
      {
        path: '/',
        element: <PrivateRoute element={<Dashboard />} />
      },
      {
        path: '/analytics',
        element: <PrivateRoute element={<Analytics />} />
      },
      {
        path: '/orders',
        element: <PrivateRoute premium={true} element={<Orders />} />
      },
      {
        path: '/analyticsReport/:type',
        element: <PrivateRoute element={<AnalyticsData />} />
      },
      {
        path: '/chats',
        element: <PrivateRoute element={<Messenger />} />
      },
      {
        path: '/campaigns/:id',
        element: <PrivateRoute element={<Campaigns />} />
      },
      {
        path: '/AllCampaigns',
        element: <PrivateRoute element={<Campaigns />} />
      },
      {
        path: '/campaignDetails/:id',
        element: <PrivateRoute element={<CampaignDetails />} />
      },
      {
        path: '/customers',
        element: <PrivateRoute element={<Customer />} />
      },
      {
        path: '/templates',
        element: <PrivateRoute element={<Template />} />
      },
      {
        path: '/templates/createNewTemplate',
        element: <PrivateRoute element={<NewTemplate />} />
      },
      // For carosel
      {
        path: '/templates/newTemplate',
        element: <PrivateRoute element={<NewTemplateCarosel />} />
      },
      {
        path: '/templates/editTemplate/:id',
        element: <PrivateRoute element={<EditTemplate />} />
      },

      // For catalog
      {
        path: '/templates/newTemplateCatalog',
        element: <PrivateRoute element={<NewTemplateCatalog />} />
      },
      {
        path: '/chatbot',
        element: <PrivateRoute element={<Chatbot />} />
      },
      // {
      //   path: '/teams',
      //   element: <PrivateRoute element={<Teams />} />
      // },
      {
        path: '/tags',
        element: <PrivateRoute element={<Tags />} />
      },
      {
        path: '/whatsappButton',
        element: <PrivateRoute element={<QRPage />} />
      },
      {
        path: '/manage',
        element: <PrivateRoute element={<Manage />} />
      },

      //  remaining routes   //
      {
        path: '/agentActivity',
        element: <PrivateRoute element={<AgentActivity />} />
      },
      {
        path: '/facebook',
        element: <PrivateRoute element={<Facebook />} />
      },
      {
        path: '/profile',
        element: <PrivateRoute element={<Profile />} />
      },
      {
        path: '/businessProfile',
        element: <PrivateRoute element={<BusinessProfile />} />
      },
      {
        path: '/changePassword',
        element: <PrivateRoute element={<ResetPassword />} />
      },

      // {
      //   path: '/forms',
      //   element: <PrivateRoute element={<FlowForms />} />
      // },
      // {
      //   path: '/formResponses/:id',
      //   element: <PrivateRoute element={<FlowFormResponses />} />
      // },
      {
        path: '/formResponses',
        element: <PrivateRoute element={<FlowFormResponses />} />
      },

      {
        path: '/activity-logs',
        element: <PrivateRoute element={<UserLogs />} />
      },

      {
        path: '/activity-logs/:id',
        element: <PrivateRoute element={<SingleUserLogs />} />
      },

      {
        path: '/medias',
        element: <PrivateRoute element={<Media />} />
      },

      {
        path: '/plans',
        element: <PrivateRoute element={<PlansPage />} />
      },
      {
        path: '/notifications',
        element: <PrivateRoute element={<Notifications />} />
      },
      {
        path: '/calendar',
        element: <PrivateRoute element={<Calendar />} />
      },
      {
        path: '/support',
        element: <PrivateRoute element={<Support />} />
      },

      {
        path: '/metaAds',
        element: <PrivateRoute premium={true} element={<FacebookLogin />} />
      },
      {
        path: '/adAnalytics/:id',
        element: <PrivateRoute element={<AdAnalytics />} />
      },
      {
        path: '/googleContacts',
        element: <PrivateRoute element={<GoogleContacts />} />
      },
      {
        path: '/createAds',
        element: <PrivateRoute element={<AdsCreate />} />
      },
      {
        path: '/buildChatbot/:id',
        element: <PrivateRoute element={<BuildChatbot />} />
      },
      {
        path: '/catalog',
        element: <PrivateRoute premium={true} element={<Catalog />} />
      },
      {
        path: '/products/:id',
        element: <PrivateRoute element={<Products />} />
      },

      {
        path: '*',
        element: <Status404 />
      }
    ]
  }
];

export default routes;
