import { te } from 'date-fns/locale';
import { axiosInstance } from './axios';

// the below code is old one for create template, (with the json structure)
const createTextTemplate = async (
  templateName,
  updatedHeaderContent,
  updatedBodyContent,
  category,
  language,
  contentData,
  footerContent,
  buttonTextOne,
  buttonTextTwo,
  buttonTextThree,
  callToActionButtonTextOne,
  callToActionButtonTextTwo,
  callToActionButtonTextThree,
  url,
  mobile,
  textPlaceholders,
  bodyPlaceholders,
  selectedMediaId,
  buttonTextFlow,
  flowID,
  cards,
  carouselHeaderData,
  securityRecommendation,
  expirationTime,
  copyCode,
  multipleButtons,
  multipleCallToActionUrl,
  templateType
) => {
  try {
    let templateComponents = [];
    // const formData = new FormData();

    

    // Handle Image
    // if (contentData && contentData.image) {
    //   formData.append('uploadedImage', contentData.image);
    // }

    // // Handle Video
    // if (contentData && contentData.video) {
    //   formData.append('uploadedVideo', contentData.video);
    // }

    // // Handle Document
    // if (contentData && contentData.application) {
    //   formData.append('uploadedDocument', contentData.application);
    // }

    // If header is text
    if (updatedHeaderContent) {
      const hasHeaderValues = textPlaceholders.length > 0;

      const headerComponent = {
        type: 'HEADER',
        format: 'TEXT',
        text: updatedHeaderContent
      };

      if (hasHeaderValues) {
        headerComponent.example = { header_text: textPlaceholders };
      }
      templateComponents.push(headerComponent);
    }

    //  If header is image
    if (contentData && contentData.image) {
      templateComponents.push({
        type: 'HEADER',
        format: 'IMAGE',
        example: {
          header_handle: contentData?.image?.media_id
        }
      });
    }

    //  If header is video
    if (contentData && contentData.video) {
      templateComponents.push({
        type: 'HEADER',
        format: 'VIDEO',
        example: {
          header_handle: contentData?.video?.media_id
        }
      });
    }

    //  If header is document
    if (contentData && contentData.application) {
      templateComponents.push({
        type: 'HEADER',
        format: 'DOCUMENT',
        example: {
          header_handle: contentData?.application?.media_id
        }
      });
    }

    // Add BODY component conditionally
    if (updatedBodyContent) {
      const hasBodyValues = bodyPlaceholders.length > 0;

      const bodyComponent = {
        type: 'BODY',
        text: updatedBodyContent
      };

      if (hasBodyValues) {
        bodyComponent.example = { body_text: [bodyPlaceholders] };
      }

      templateComponents.push(bodyComponent);
    }

    // Add FOOTER component conditionally
    if (footerContent) {
      templateComponents.push({
        type: 'FOOTER',
        text: footerContent
      });
    }

    if (category == 'AUTHENTICATION') {
      if (securityRecommendation || expirationTime || copyCode) {
        templateComponents.push(
          {
            type: 'BODY',
            add_security_recommendation:
              securityRecommendation === true ? true : false
          },
          {
            type: 'FOOTER',
            code_expiration_minutes: expirationTime ? expirationTime : 0
          }
        );
      }
    }

    

    // Add BUTTONS component conditionally
    const buttons = [];

    if (buttonTextOne) {
      buttons.push({
        type:
          category === 'CATALOG' && templateType === 'single'
            ? 'CATALOG'
            : templateType === 'multi'
            ? 'MPM'
            : 'QUICK_REPLY',
        text:
          category === 'CATALOG' && templateType === 'single'
            ? 'View catalog'
            : templateType === 'multi'
            ? 'View items'
            : buttonTextOne
      });
    }

    if (category == 'AUTHENTICATION') {
      buttons.push({
        type: 'otp',
        otp_type: 'copy_code',
        text: copyCode
      });
    }

    if (buttonTextTwo) {
      buttons.push({
        type: 'QUICK_REPLY',
        text: buttonTextTwo
      });
    }

    if (buttonTextThree) {
      buttons.push({
        type: 'QUICK_REPLY',
        text: buttonTextThree
      });
    }
    if (callToActionButtonTextOne) {
      buttons.push({
        type: 'URL',
        text: callToActionButtonTextOne,
        url: url
      });
    }

    

    if (callToActionButtonTextTwo) {
      buttons.push({
        type: 'PHONE_NUMBER',
        text: callToActionButtonTextTwo,
        phone_number: mobile
      });
    }

    

    if (callToActionButtonTextThree && buttonTextFlow == '') {
      buttons.push({
        type: 'QUICK_REPLY',
        text: callToActionButtonTextThree
      });
    }

    

    if (buttonTextFlow) {
      buttons.push({
        type: 'FLOW',
        text: buttonTextFlow,
        flow_id: flowID,
        navigate_screen: 'SCREENONE',
        flow_action: 'navigate'
      });
    }
    if(multipleCallToActionUrl.length>0){

      buttons.push(...multipleCallToActionUrl);
    }
    if(multipleButtons.length>0){

      buttons.push(...multipleButtons);
    }
    if (buttons.length > 0) {
      templateComponents.push({
        type: 'BUTTONS',
        buttons: buttons
      });
    }

    if (cards && cards.length > 0) {
      templateComponents.push({
        type: 'CAROUSEL',
        cards: cards.map((card) => ({
          components: [
            {
              type: 'HEADER',
              format: card.headerType.toUpperCase(),
              example: {
                header_handle: [card.headerContent]
              }
            },
            {
              type: 'BODY',
              text: card.body
            },
            // {
            //   type: 'BUTTONS',
            //   buttons: card.buttons.map((button) => ({
            //     type: button.type.toUpperCase().replace(' ', '_'),
            //     text: button.text,
            //     ...(button.type === 'url' && { url: button.url }),
            //     ...(button.type === 'phone' && { phone_number: button.url }),
            //   })),
            // },
            {
              type: 'BUTTONS',
              buttons: card.buttons.map((button) => {
                const formattedButton = {
                  type: button.type.toUpperCase().replace(' ', '_'),
                  text: button.text
                };

                if (button.type === 'url') {
                  formattedButton.url = button.url;
                } else if (button.type === 'phone_number') {
                  formattedButton.phone_number = button.phone_number.replace(
                    '+',
                    ''
                  ); // Remove '+' from phone number
                }

                return formattedButton;
              })
            }
          ]
        }))
      });
    }
    if (category === 'CAROUSEL') {
      category = 'MARKETING';
    }

    if (category === 'CATALOG') {
      category = 'MARKETING';
    }

    

    const Data = {
      name: templateName,
      language: language,
      category: category,
      components: templateComponents
    };

    const response = await axiosInstance.post(
      'templates/create_text_template',
      { Data, selectedMediaId, carouselHeaderData }
    );
    templateComponents = [];
    selectedMediaId = '';

    return response.data;
    // return null;
  } catch (error) {
    return null;
  }
};
const updateTextTemplate = async (
  templateName,
  updatedHeaderContent,
  updatedBodyContent,
  category,
  language,
  contentData,
  footerContent,
  buttonTextOne,
  buttonTextTwo,
  buttonTextThree,
  callToActionButtonTextOne,
  callToActionButtonTextTwo,
  callToActionButtonTextThree,
  url,
  mobile,
  textPlaceholders,
  bodyPlaceholders,
  selectedMediaId,
  buttonTextFlow,
  flowID,
  cards,
  carouselHeaderData,
  securityRecommendation,
  expirationTime,
  copyCode,
  templateID,
  multipleButtons,
  multipleCallToActionUrl,
  templateType,
) => {
  try {
    console.log("templateID",templateID)
    let templateComponents = [];
    // const formData = new FormData();

    

    // Handle Image
    // if (contentData && contentData.image) {
    //   formData.append('uploadedImage', contentData.image);
    // }

    // // Handle Video
    // if (contentData && contentData.video) {
    //   formData.append('uploadedVideo', contentData.video);
    // }

    // // Handle Document
    // if (contentData && contentData.application) {
    //   formData.append('uploadedDocument', contentData.application);
    // }

    // If header is text
    if (updatedHeaderContent) {
      const hasHeaderValues = textPlaceholders.length > 0;

      const headerComponent = {
        type: 'HEADER',
        format: 'TEXT',
        text: updatedHeaderContent
      };

      if (hasHeaderValues) {
        headerComponent.example = { header_text: textPlaceholders };
      }
      templateComponents.push(headerComponent);
    }

    //  If header is image
    if (contentData && contentData.image) {
      templateComponents.push({
        type: 'HEADER',
        format: 'IMAGE',
        example: {
          header_handle: contentData?.image?.media_id
        }
      });
    }

    //  If header is video
    if (contentData && contentData.video) {
      templateComponents.push({
        type: 'HEADER',
        format: 'VIDEO',
        example: {
          header_handle: contentData?.video?.media_id
        }
      });
    }

    //  If header is document
    if (contentData && contentData.application) {
      templateComponents.push({
        type: 'HEADER',
        format: 'DOCUMENT',
        example: {
          header_handle: contentData?.application?.media_id
        }
      });
    }

    // Add BODY component conditionally
    if (updatedBodyContent) {
      const hasBodyValues = bodyPlaceholders.length > 0;

      const bodyComponent = {
        type: 'BODY',
        text: updatedBodyContent
      };

      if (hasBodyValues) {
        bodyComponent.example = { body_text: [bodyPlaceholders] };
      }

      templateComponents.push(bodyComponent);
    }

    // Add FOOTER component conditionally
    if (footerContent) {
      templateComponents.push({
        type: 'FOOTER',
        text: footerContent
      });
    }

    if (category == 'AUTHENTICATION') {
      if (securityRecommendation || expirationTime || copyCode) {
        templateComponents.push(
          {
            type: 'BODY',
            add_security_recommendation:
              securityRecommendation === true ? true : false
          },
          {
            type: 'FOOTER',
            code_expiration_minutes: expirationTime ? expirationTime : 0
          }
        );
      }
    }

    

    // Add BUTTONS component conditionally
    const buttons = [];

    if (buttonTextOne) {
      buttons.push({
        type:
          category === 'CATALOG' && templateType === 'single'
            ? 'CATALOG'
            : templateType === 'multi'
            ? 'MPM'
            : 'QUICK_REPLY',
        text:
          category === 'CATALOG' && templateType === 'single'
            ? 'View catalog'
            : templateType === 'multi'
            ? 'View items'
            : buttonTextOne
      });
    }

    if (category == 'AUTHENTICATION') {
      buttons.push({
        type: 'otp',
        otp_type: 'copy_code',
        text: copyCode
      });
    }

    if (buttonTextTwo) {
      buttons.push({
        type: 'QUICK_REPLY',
        text: buttonTextTwo
      });
    }

    if (buttonTextThree) {
      buttons.push({
        type: 'QUICK_REPLY',
        text: buttonTextThree
      });
    }
    if (callToActionButtonTextOne) {
      buttons.push({
        type: 'URL',
        text: callToActionButtonTextOne,
        url: url
      });
    }

    

    if (callToActionButtonTextTwo) {
      buttons.push({
        type: 'PHONE_NUMBER',
        text: callToActionButtonTextTwo,
        phone_number: mobile
      });
    }

    

    if (callToActionButtonTextThree && buttonTextFlow == '') {
      buttons.push({
        type: 'QUICK_REPLY',
        text: callToActionButtonTextThree
      });
    }

    

    if (buttonTextFlow) {
      buttons.push({
        type: 'FLOW',
        text: buttonTextFlow,
        flow_id: flowID,
        navigate_screen: 'SCREENONE',
        flow_action: 'navigate'
      });
    }
    multipleCallToActionUrl
    if(multipleButtons.length>0){

      buttons.push(...multipleButtons);
    }
    if (buttons.length > 0) {
      templateComponents.push({
        type: 'BUTTONS',
        buttons: buttons
      });
    }

    if (cards && cards.length > 0) {
      templateComponents.push({
        type: 'CAROUSEL',
        cards: cards.map((card) => ({
          components: [
            {
              type: 'HEADER',
              format: card.headerType.toUpperCase(),
              example: {
                header_handle: [card.headerContent]
              }
            },
            {
              type: 'BODY',
              text: card.body
            },
            // {
            //   type: 'BUTTONS',
            //   buttons: card.buttons.map((button) => ({
            //     type: button.type.toUpperCase().replace(' ', '_'),
            //     text: button.text,
            //     ...(button.type === 'url' && { url: button.url }),
            //     ...(button.type === 'phone' && { phone_number: button.url }),
            //   })),
            // },
            {
              type: 'BUTTONS',
              buttons: card.buttons.map((button) => {
                const formattedButton = {
                  type: button.type.toUpperCase().replace(' ', '_'),
                  text: button.text
                };

                if (button.type === 'url') {
                  formattedButton.url = button.url;
                } else if (button.type === 'phone_number') {
                  formattedButton.phone_number = button.phone_number.replace(
                    '+',
                    ''
                  ); // Remove '+' from phone number
                }

                return formattedButton;
              })
            }
          ]
        }))
      });
    }
    if (category === 'CAROUSEL') {
      category = 'MARKETING';
    }

    if (category === 'CATALOG') {
      category = 'MARKETING';
    }


    const Data = {
      name: templateName,
      language: language,
      category: category,
      components: templateComponents
    };

    const response = await axiosInstance.put(
      `templates/update_text_template/${templateID}`,
      { Data, selectedMediaId, carouselHeaderData }
    );
    templateComponents = [];
    selectedMediaId = '';

    return response.data;
    // return null;
  } catch (error) {
    console.log("error",error)
    return null;
  }
};

// new create template api (only send the states )
// const createTextTemplate = async (
//   templateName,
//   updatedHeaderContent,
//   updatedBodyContent,
//   category,
//   language,
//   contentData,
//   footerContent,
//   buttonTextOne,
//   buttonTextTwo,
//   buttonTextThree,
//   callToActionButtonTextOne,
//   callToActionButtonTextTwo,
//   callToActionButtonTextThree,
//   url,
//   mobile,
//   textPlaceholders,
//   bodyPlaceholders
// ) => {
//   try {
//     const formData = new FormData();

//     // Handle Image
//     if (contentData && contentData.image) {
//       formData.append('uploadedImage', contentData.image);
//     }

//     // Handle Video
//     if (contentData && contentData.video) {
//       formData.append('uploadedVideo', contentData.video);
//     }

//     // Handle Document
//     if (contentData && contentData.application) {
//       formData.append('uploadedDocument', contentData.application);
//     }

//     const response = await axiosInstance.post(
//       'templates/create_text_template',
//       {
//         templateName,
//         updatedHeaderContent,
//         updatedBodyContent,
//         category,
//         language,
//         contentData,
//         footerContent,
//         buttonTextOne,
//         buttonTextTwo,
//         buttonTextThree,
//         callToActionButtonTextOne,
//         callToActionButtonTextTwo,
//         callToActionButtonTextThree,
//         url,
//         mobile,
//         textPlaceholders,
//         bodyPlaceholders,
//         file: formData
//       }
//     );
//     return response.data;
//   } catch (error) {
//     return null;
//   }
// };

const listAllTemplates = async () => {
  const response = await axiosInstance.get('/templates/get_all_templates');

  return response.data;
};

const getTemplatesById = async (templateId) => {
  const response = await axiosInstance.get(
    `/templates/get_template_by_id/${templateId}`
  );
  return response.data;
};
const getTemplatesByIdForEdit = async (templateId) => {
  const response = await axiosInstance.get(
    `/templates/get_transformed_template_by_id/${templateId}`
  );



  return response.data;
};

const editTemplateById = async (templateName) => {
  const response = await axiosInstance.get(
    `/templates/get_template_by_name?name=${templateName}`
  );
  return response.data;
};

const deleteTemplateByName = async (name) => {
  const response = await axiosInstance.delete(
    `/templates/delete_template_by_name?name=${name}`
  );
  return response.data;
};

const sendSegmentId = async (
  templateId,
  selectedSegment,
  selectedImage,
  broadcastName,
  scheduledAt,
  selectedTeamMember,
  selectedMediaId
) => {
  const formData = new FormData();
  formData.append('image', selectedImage);
  formData.append('templateId', templateId);
  formData.append('selectedSegment', selectedSegment);
  formData.append('broadcastName', broadcastName);
  formData.append('scheduledAt', scheduledAt);
  formData.append('selectedTeamMember', selectedTeamMember);
  formData.append('selectedMediaId', selectedMediaId);

  // formData.append('selectedFile', selectedFile);

  try {
    const response = await axiosInstance.post(
      '/templates/send_text_template',
      formData
    );
    return response.data;
  } catch (error) {
    console.error('Error sending segment ID:', error);
    throw error;
  }
};

const sendSingleSegmentId = async (templateId, customerId, id) => {
  try {
    const res = await axiosInstance.post(
      `/templates/send_text_template_to_customer`,
      { templateId, customerId, id }
    );
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    
  }
};

const BroadcastTemplate = async (
  selectedSegmentCustomers,
  selectedTemplate,
  headerText,
  bodyText,
  selectedMediaId,
  broadcastId
) => {
  try {
    // Define an async function for sending a template to a single recipient
    const sendTemplateToRecipient = async (customer) => {
      const recipientPhoneNumber = customer?.mobile;

      // Build the template object
      const template = {
        name: selectedTemplate.name,
        language: {
          code: selectedTemplate?.language
        }
      };

      // Check if the template has components
      if (
        selectedTemplate?.components &&
        selectedTemplate?.components.length > 0
      ) {
        const dynamicComponents = [];

        selectedTemplate.components.forEach((component) => {
          // Customize the component based on your requirements
          if (component.type === 'HEADER' || component.type === 'BODY') {
            const dynamicComponent = { type: component.type, parameters: [] };
            if (bodyText.length > 0 || headerText.length > 0) {
              // Add parameters based on selected variables if they are not empty
              if (
                component.type === 'BODY' &&
                bodyText &&
                bodyText.length > 0
              ) {
                bodyText.forEach((bodyVariable) => {
                  const bodyValue = customer[bodyVariable]; // Extract value from customer data
                  dynamicComponent.parameters.push({
                    type: 'text',
                    text: bodyValue
                  });
                });
              }
              if (
                component.type === 'HEADER' &&
                component.format === 'TEXT' &&
                headerText &&
                headerText.length > 0
              ) {
                headerText.forEach((headerVariable) => {
                  const headerValue = customer[headerVariable]; // Extract value from customer data
                  dynamicComponent.parameters.push({
                    type: 'text',
                    text: headerValue
                  });
                });
              } else if (
                component.type === 'HEADER' &&
                component.format === 'IMAGE'
              ) {
                if (
                  component?.example?.header_handle &&
                  component.example.header_handle.length > 0
                ) {
                  dynamicComponent.parameters.push({
                    type: 'image',
                    image: {
                      id: selectedMediaId
                    }
                  });
                } else {
                  // Handle the case when there is no example field or header_handle in the selected template
                  console.error(
                    'Selected template is missing the required information for the header image.'
                  );
                }
              } else if (
                component.type === 'HEADER' &&
                component.format === 'VIDEO'
              ) {
                if (component?.example?.header_handle) {
                  dynamicComponent.parameters.push({
                    type: 'video',
                    video: {
                      id: selectedMediaId
                    }
                  });
                } else {
                  console.error(
                    'Selected template is missing the required information for the header video.'
                  );
                }
              } else if (
                component.type === 'HEADER' &&
                component.format === 'DOCUMENT'
              ) {
                if (component?.example?.header_handle) {
                  dynamicComponent.parameters.push({
                    type: 'document',
                    document: {
                      id: selectedMediaId
                    }
                  });
                } else {
                  console.error(
                    'Selected template is missing the required information for the header document.'
                  );
                }
              }
            }
            dynamicComponents.push(dynamicComponent);
          }
        });

        // Add the components field only if there are parameters
        if (dynamicComponents?.length > 0) {
          template.components = dynamicComponents;
        }
      }

      // Return the promise for this customer
      return axiosInstance.post(
        `templates/send_text_template?broadcastId=${broadcastId}`,
        {
          messaging_product: 'whatsapp',
          recipient_type: 'individual',
          to: recipientPhoneNumber,
          type: 'template',
          template
        }
      );
    };

    // Use Promise.all to wait for all promises to resolve
    const responses = await Promise.all(
      selectedSegmentCustomers.map(sendTemplateToRecipient)
    );

    return responses; // Return an array of responses
  } catch (error) {
    console.error(error.message);
    return [];
  }
};

const listSegmentTemplates = async (segment) => {
  const response = await axiosInstance.get(
    `/templates/get_list_templates?segmentId=${segment}`
  );
  return response?.data;
};

const createFlow = async (flowName) => {
  try {
    const response = await axiosInstance.post('flow/createFlow', {
      flowName
    });
    return response.data;
  } catch (error) {
    
    return null;
  }
};

const fetchQuickReplyTemplatesForAutomation = async () => {
  const response = await axiosInstance.get(
    `/templates/fetchQuickReplyTemplatesForAutomation`
  );
  return response?.data;
};

const TemplateApiCalls = {
  createTextTemplate,
  listAllTemplates,
  getTemplatesById,
  editTemplateById,
  deleteTemplateByName,
  BroadcastTemplate,
  // getTemplateByName,
  sendSegmentId,
  sendSingleSegmentId,
  listSegmentTemplates,
  createFlow,
  fetchQuickReplyTemplatesForAutomation,
  getTemplatesByIdForEdit,
  updateTextTemplate
};
export default TemplateApiCalls;
