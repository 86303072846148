
export const backend_url='http://localhost:7979'
// export const backend_url='https://api.wapp.biz'

export const shippingStatuses = [
    {
      status: "pending",
      description: "Your order has been received and is being processed.",
      example: "Thank you! We've received your order and are preparing it for shipment.",
    },
    {
      status: "order placed",
      description: "Your order has been received and is being processed.",
      example: "Thank you! We've received your order and are preparing it for shipment.",
    },
    {
      status: "order confirmed",
      description: "Your payment has been confirmed, and the order is ready for fulfillment.",
      example: "Your order has been confirmed and is now being packed.",
    },
    {
      status: "shipped",
      description: "Your order has been shipped and is on its way.",
      example: "Good news! Your order has been shipped. Track your package using this link: [tracking link].",
    },
    {
      status: "in transit",
      description: "Your package is moving through the carrier's delivery network.",
      example: "Your order is currently in transit and is expected to arrive soon.",
    },
    {
      status: "out for delivery",
      description: "The delivery agent has your package and is en route to the delivery location.",
      example: "Your package is out for delivery and will reach you shortly.",
    },
    {
      status: "delivered",
      description: "Your order has been successfully delivered.",
      example: "Your package has been delivered. Thank you for shopping with us!",
    },
    {
      status: "delivery failed",
      description: "Delivery was attempted but was unsuccessful.",
      example: "We attempted to deliver your package but were unable to. Please contact us to reschedule.",
    },
    {
      status: "cancelled",
      description: "Delivery was attempted but was unsuccessful.",
      example: "We attempted to deliver your package but were unable to. Please contact us to reschedule.",
    },
  ];
  

  export const capitalizeWords = (text) => {
    return text.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  export  const sortData=(uniqueOrders,sort="createdAt") =>{
    const arrCopy = [...uniqueOrders];
    const sortedOrders =   arrCopy.sort((a, b) => {
      // Sort by date (createdAt or updatedAt)
      if (sort === 'createdAt' || sort === 'updatedAt') {
        const dateComparison = new Date(b[sort]) - new Date(a[sort]);
        if (dateComparison !== 0) return dateComparison; // If dates are different, return the comparison result
      } 
      // Sort by customer name alphabetically
      else if (sort === 'customer_name') {
        const nameComparison = a[sort].localeCompare(b[sort]);
        if (nameComparison !== 0) return nameComparison; // If names are different, return the comparison result
      } 
      // Sort by numeric value
      else {
        const numericComparison = b[sort] - a[sort];
        if (numericComparison !== 0) return numericComparison; // If values are different, return the comparison result
      }
    
      // If both primary fields are the same, fallback to sorting by _id
      return b._id.toString() - a._id.toString(); // Sort by _id if all other values are the same
    });
return sortedOrders
  }
  export const IDate = (date) => {
    const options = {
      day: 'numeric', // Day of the month
      month: 'short', // Abbreviated month name (e.g., Jan, Feb)
      year: 'numeric', // Full year
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // AM/PM format
    };
    return new Date(date).toLocaleString('en-US', options).replace(',', '');
  };
  



















