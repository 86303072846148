import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  List,
  styled,
  Button,
  ListItem,
  Typography,
  Badge,
  alpha,
  Tooltip,
  tooltipClasses
} from '@mui/material';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import CatalogIcon from 'src/assets/ManagePageIcons/Catalogue.svg';
// import CalendarIcon from 'src/assets/svgIcons/CalendarIcon.svg'
import { useAuth } from 'src/Authentication/AuthContext';
import { useSelector } from 'react-redux';
import { getDecryptedData } from 'src/utility/LocalStorageManager';
// new icons
import dashboardIcon from '../../../../assets/customerSidebarIcons/dashboard.svg';
import analytics from '../../../../assets/customerSidebarIcons/analytics.svg';
import allCampaign from '../../../../assets/customerSidebarIcons/campaign.svg';
import chatIcon from '../../../../assets/customerSidebarIcons/chat.svg';
import customerIcon from '../../../../assets/customerSidebarIcons/customer.svg';
import formIcon from '../../../../assets/customerSidebarIcons/forms.svg';
import chatGif from '../../../../assets/render/Chatbot.gif';
import chatbotIcon from '../../../../assets/customerSidebarIcons/chatbot.svg';
import manageIcon from '../../../../assets/customerSidebarIcons/manage.svg';
import templateIcon from '../../../../assets/customerSidebarIcons/template.svg';
import teamsIcon from '../../../../assets/customerSidebarIcons/teams.svg';
import whatsappButtonIcon from '../../../../assets/customerSidebarIcons/whatsAppButton.svg';
import tagsIcon from '../../../../assets/customerSidebarIcons/tags.svg';
import metaIcon from '../../../../assets/customerSidebarIcons/meta.svg';
import catalogIcon from 'src/assets/customerSidebarIcons/catalog.svg';
import orderIcon from 'src/assets/customerSidebarIcons/Order.svg';
import { TabMain_Sidebar } from 'src/content/applications/Manage/manageStyle';



const MenuWrapper = styled(Box)(
  ({ theme }) => `
  background-color: ${theme.wappBizColor.white};
  .MuiList-root {
    padding:0px;
    margin:0px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    
    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      .MuiListItem-root {
        width: auto !important;
        padding: 4px 4px;
        .MuiBadge-root {
          min-width: 0 !important;

          position: absolute;
          right: ${theme.spacing(3.2)};
          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }

        .MuiButton-root {
          min-width: 0 !important;
          display: flex;
          color: ${theme.colors.alpha.black[100]};
          background-color: transparent;
          justify-content: center;
          transition: background-color ${theme.transitions.duration.short}ms;
          

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};
            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.h1.fontSize};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(15)};
          }

          &:hover {
            // background-color: #BEFCC5;
            background-color: ${theme.wappBizColor.natural[100]};
             min-width:0px !important;
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              // color: ${theme.colors.alpha.trueWhite[100]};
              color: ${theme.wappBizColor.natural[100]};
            }
          }

          &.active {
            // background-color: #27AB69;
            background-color: transparent;
            min-width:0px !important;
            // color: ${theme.colors.alpha.white[100]}; 
            color: ${theme.wappBizColor.primary[700]};           
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }

          &:active {  // This applies when the button is pressed
          background-color: ${theme.wappBizColor.primary[100]};           
           .MuiButton-startIcon,
           .MuiButton-endIcon {
           // If you want the icons to change color on click, specify here
           color: ${theme.wappBizColor.primary[700]};
           }
          }

          &.disabled {
            min-width:0px !important;
            pointer-events: none;
            opacity: 0.5;
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
               
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {
                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const IconHeading = styled(Typography)`
  text-align: center;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  margin-top: 5px;
`;

// Custom styled Tooltip
// const CustomTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} arrow />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.wappBizColor.black,
//     color: theme.wappBizColor.white,
//     fontWeight: 400,
//     alignItems:'center',
//     fontSize: theme.typography.pxToRem(12),
//     // border: `1px solid ${theme.palette.primary.main}`,
//     padding: theme.spacing(1),
//     borderRadius: '4px',
//     height: '40px'
//   },
//   [`& .${tooltipClasses.arrow}`]: {
//     color: theme.wappBizColor.black
//   }
// }));



function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const {
    unreadChatCounts,
    businessProfileData,
    isRegister,
    unReadOrder,
    unreadArchivedChatCounts
  } = useSelector((state) => state.wapp);

  //

  const { isAuthenticated } = useAuth();
  //

  // For allowing and hiding Premium feature
  const [isAllow, SetIsAllow] = useState(false);

  // Fetching Data from Context
  useEffect(() => {
    if (isAuthenticated) {
      // const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      const userInfo = getDecryptedData('userInfo');
      //
      if (userInfo.planName === 'Premium') {
        SetIsAllow(true);
      }
    }
  }, [isAuthenticated]);

  const menuItems = [
    { to: '/', activeIcon:chatGif, icon: dashboardIcon, label: 'Dashboard' },
    { to: '/analytics', activeIcon:chatGif, icon: analytics, label: 'Analytics' },
    {
      to: '/chats',
      activeIcon:chatGif, icon: chatIcon,
      label: 'Chats',
      badgeContent: unreadChatCounts?.length
    },
    { to: '/AllCampaigns', activeIcon:chatGif, icon: allCampaign, label: 'Campaigns' },
    // { to: '/broadcasts', activeIcon:chatGif, icon: broadcastIcon, label: 'Broadcast' },
    { to: '/customers', activeIcon:chatGif, icon: customerIcon, label: 'Customers' },
    { to: '/templates', activeIcon:chatGif, icon: templateIcon, label: 'Templates' },
    { to: '/chatbot', activeIcon:chatGif, icon: chatbotIcon, label: 'Chatbot' },
    // { to: '/calendar', activeIcon:chatGif, icon: CalendarIcon, label: 'calendar' }
    // { to: '/teams', activeIcon:chatGif, icon: teamsIcon, label: 'Team' },
    {
      to: '/whatsappButton',
      activeIcon:chatGif, icon: whatsappButtonIcon,
      label: 'Whatsapp Button'
    },
    { to: '/tags', activeIcon:chatGif, icon: tagsIcon, label: 'Tags' },
    // { to: '/metaAds', activeIcon:chatGif, icon: metaIcon, label: 'Meta Ads' },
    // { to: '/catalog', activeIcon:chatGif, icon: catalogIcon, label: 'Catalog' },
    // {
    //   to: '/orders', activeIcon:chatGif, icon: orderIcon, label: 'Orders', badgeContent: unReadOrder
    // },
    { to: '/manage', activeIcon:chatGif, icon: manageIcon, label: 'Manage' },
    { to: '/formResponses', activeIcon:chatGif, icon: formIcon, label: 'Form Responses' }
  ];

  // if (isAllow && businessProfileData?.message_limit !== '250') {
  //   menuItems.push({
  //     to: '/formResponses',
  //     activeIcon:chatGif, icon: formIcon,
  //     label: 'Form Responses'
  //   });
  // }
  // if (isAllow && businessProfileData?.message_limit !== '250') {
  //   // 1) Pop the last item (assuming it's 'Settings')
  //   const lastItem = menuItems.pop();

  //   // 2) Push the "Form Responses" item (second to last)
  //   menuItems.push({
  //     to: '/formResponses',
  //     activeIcon:chatGif, icon: formIcon,
  //     label: 'Form Responses',
  //   });

  //   // 3) Push the last item (Settings) back to the end
  //   menuItems.push(lastItem);
  // }
  // menuItems.push({ to: '/catalog', activeIcon:chatGif, icon: CatalogIcon, label : 'Catalog'})
  const location = useLocation();
  return (
    <MenuWrapper>
      <List component="div">
        <SubMenuWrapper>
          <List component="div">
            {menuItems.map(({ to, icon, label, badgeContent,activeIcon }) => (
            <TabMain_Sidebar businessProfileData={businessProfileData} activeIcon={activeIcon}  RouterLink={RouterLink}  closeSidebar={closeSidebar}  isRegister={isRegister}  
            badgeContent={badgeContent}   to={to} label={label}  icon={icon} isActive={location.pathname===to} />
            ))}
          </List>
        </SubMenuWrapper>
      </List>
    </MenuWrapper>
  );
}

export default SidebarMenu;
